import type { NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useAuth } from '@/components/auth/AuthContext';

const Home: NextPage = () => {
  const router = useRouter();
  const { authUser, authLoading } = useAuth();
  const [animateLogo, setAnimateLogo] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        if (!authLoading) {
          if (authUser) {
            router.push('/dashboard');
          } else {
            router.push('/login');
          }
        }
      }, 1000);
      setAnimateLogo(false);
    }, 4000);
  }, [authLoading, authUser, router]);

  return (
    <section className='bg-dark-blue relative flex h-screen w-screen flex-col items-center justify-center'>
      <Image
        className={`translate transition-all ${
          !animateLogo ? 'opacity-100' : 'scale-50 opacity-0'
        }`}
        src='/images/dark-logo.webp'
        width={100}
        height={100}
        alt='NZVC logo'
      />

      <div
        className={`translate absolute flex items-center gap-4 rounded-lg py-4 px-8 transition-all ${
          animateLogo ? 'opacity-100' : 'scale-150 opacity-0'
        }`}
      >
        <div className='relative flex h-[60px] w-[60px] items-center justify-center p-3'>
          <span className='mt-2'>
            <Image src='/images/logo.webp' height={38} width={38} alt='' />
          </span>
          <div className='border-green-transparent absolute flex h-full w-full animate-spin items-center justify-center rounded-full border-[3px] border-l-green-800'></div>
        </div>
      </div>
    </section>
  );
};

export default Home;
